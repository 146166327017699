

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddProductGradeModal from "@/components/modals/forms/AddProductGradeModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, {business_types} from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Modal } from "bootstrap";
import AddProductInventoryModal from "@/components/modals/forms/AddProductInventoryModal.vue";


export default defineComponent({
 props: 
  {
      id: { required: true }
  },
  name: "inventory-listing",
  components: {
   AddProductInventoryModal,
  },
  
  setup(props) {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);    
    const loadingData = ref<boolean>(true);
    const searchTearm = ref('');
    var ids = ref(props.id);
    interface ViewPrice {
      product_id,    
      inward_qty,   
      uom_name,
      product_price_per_uom,
      valid_from,
      valid_upto,
      currency_name,
      incoterm_display_text,
    };

    var paginationData = ref({})
    var resultsView1 = ref<Array<ViewPrice>>([]);
    paginationData.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

    const inventory_modal = async(product_id) => {
      const modal = new Modal(document.getElementById("kt_modal_add_inventory"));
      (document.querySelector('#inventory_id') as HTMLInputElement).value = product_id;
        modal.show();
      
    }

    
    var tableData = ref<Array<ViewPrice>>([]);
  
     const getProductInventoryDetails = async () => {
     try {

      var values = { "product_id"  :  props.id}

      await store.dispatch(Actions.CUST_GET_PRODUCT_PRICE_LIST, values).then(({ data }) => {

      tableData.value = ([]);

         for (var j = 0; j < data.length; j++){
              resultsView1.value = Array({
                  inward_qty: data[j].inward_qty,
                  uom_name: data[j].uom_name,
                  currency_name:data[j].currency_name,
                  valid_from: data[j].valid_from,
                  valid_upto: data[j].valid_upto,
                  product_id: data[j].product_id,
                  product_price_per_uom: data[j].product_price_per_uom,
                  incoterm_display_text: data[j].incoterm_display_text,
                  delivery_term_display_text: data[j].delivery_term_display_text
              })
              tableData.value.splice(j, resultsView1.value.length, ...resultsView1.value); 
          }
           loadingData.value = false;

        }).catch(({ response }) => {
          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };
    
      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });
                    
      onMounted( async () => {
         await getProductInventoryDetails();
      });  

      
      
    return {
      tableData,      
      checkedCompany,
      resultsView1,
      getProductInventoryDetails,
      inventory_modal,
      ids,
      count,      
      paginationData,
      loadingData
    };  

  }  

});
