
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import {mask} from 'vue-the-mask'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import {active} from "@/core/data/genericData";

export default defineComponent({
  name: "add-product-modal",
  directives: {mask}, 
  props: {
    data: {
        type: Object
    }
  },
  components: {
    
    },

  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const editProductModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const router = useRouter(); 
    const active_list_data = active;
    var formData = ref({})
   
    //  const branch = ref([]);
    //  const OnClickCompany = async (compid) => {
    //   try {
    //         var values = { "company_id"  : compid, "page"  : 1, "records_per_page" : 10 }
    //         await store.dispatch(Actions.CUST_GET_BRANCH_LIST, values).then(({ data }) => {
    //         branch.value = data.result_list;
            
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);        
    //     });
        
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

    const compseller = ref([]);
    const setCompanySellerdata = async (data) => {
      const db_data = {}
        //loading.value = true;
        try {
            var values = { "company_id"  : 0, "search_term" : data, "page"  : 1, "records_per_page" : 10 }
            await store.dispatch(Actions.CUST_GET_COMPANY_LIST, values).then(({ data }) => {
            compseller.value = data.result_list;
            //loading.value = false;
            console.log(compseller.value)
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const productdata = ref([]);
    const setProductDropdown = async (data) => {
      const db_data = {}
        //loading.value = true;
        try {
            var values = {"search_term" : data}
            await store.dispatch(Actions.CUST_GET_PRODUCT_SELECTBOX, values).then(({ data }) => {
            productdata.value = data;
            //loading.value = false;
            console.log(productdata.value)
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const company = ref([]);
    const setCompanyData = async (data) => {
      const db_data = {}
        //loading.value = true;
        try {
            var values = { "company_id"  : 0, "page"  : 1, "records_per_page" : 10 }
            await store.dispatch(Actions.CUST_GET_COMPANY_LIST, values).then(({ data }) => {
            company.value = data.result_list;
            //loading.value = false;
            
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const parent = ref([]);
    const setParentData = async (data) => {
        //loading.value = true;
        try {
          //var values = {"search_term" : data}
          await store.dispatch(Actions.CUST_GET_PARENT_CATEGORY).then(({ data }) => {
          parent.value = data;
          //loading.value = false;
         
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const making = ref([]);
    const setMakingdata = async (param) => {
      const db_data = {}
        //loading.value = true;
        try {
            await store.dispatch(Actions.CUST_GET_PRODUCT_MAKING, param).then(({ body }) => {
            making.value = body.making_process_list;
            //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const comp = ref([]);
    const setCompositiondata = async (param) => {
      const db_data = {}
        //loading.value = true;
        try {
            await store.dispatch(Actions.CUST_GET_PRODUCT_COMPOSITION, param).then(({ body }) => {
            comp.value = body.composition_types_list;
            //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }
    
    const setFormData = async (data) => {    
        console.log(data.ids);
        var j = 0;

        try {

        var values = { "product_id": data.ids, "page": 1, "records_per_page": 1 }
         await store.dispatch(Actions.CUST_GET_PRODUCT_LIST, values).then(({ data }) => {       
         
          formData.value = {
            id: data[0].product_id,
            name: data[0].product_name, 
            company_select: data[0].company_id,
            product_select: data[0].parent_product_id,
            sellercomp_select: data[0].parent_company_id,
            //branch_select : data[0].branch_id,  
            brand_select : data[0].brand_id,   
            description: data[0].product_description,
            short_name: data[0].product_short_name,
            parent_select: data[0].category_id,
            making_select: data[0].making_process_id,
            composition_select: data[0].composition_id,
            active : data[0].active,
            };
          console.log(formData.value)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }
        
    const rules = ref({
      name: [
        {
          required: true,
          message: "Product Name is required",
          trigger: "change",  
          //pattern:"^[a-zA-Z]+$",        
        },        
      ],    
      company_select: [
        {
          required: true,
          message: "Company is required",
          trigger: "change",  
        }
      ],
      parent_select:[{
          required: true,
          message: "Category is required",
          trigger: "change",  
        }
      ],
      making_select:[{
          required: true,
          message: "Making process is required",
          trigger: "change",  
        }
      ],
      composition_select: [
        {
          required: true,
          message: "Composition is required",
          trigger: "change",  
        }
      ],
       description: [
        {
          required: true,
          message: "Description is required",
          trigger: "change",  
        }
      ],
       short_name: [
        {
          required: true,
          message: "Short name is required",
          trigger: "change",  
        }
      ],
    });

    const setProductData = async (data) => {
      const db_data = {
        "product_id" : data.id,
        "product_name": data.name,
        "company_id" : data.company_select,
        "parent_company_id" : data.sellercomp_select,
        //"branch_id" : data.branch_select,
        "brand_id" : data.brand_select,
        "product_description": data.description,
        "product_short_name": data.short_name,
        "category_id": data.parent_select,    
        "making_process_id": data.making_select,
        "composition_id": data.composition_select,
        "company_trade_type_id" : 1,
        "parent_product_id" : data.product_select,
        "created_user_id": "1",
        // "category_id_top": 1,
        // "created_ip": "0.0.0.0",
        // "created_uagent": "Test",
        "active": data.active,
        }

        await store.dispatch(Actions.CUST_ADD_PRODUCT, db_data).then(({ data }) => {
          if (data){
        console.log(db_data)
            setTimeout(() => {
              loading.value = false;
//
              Swal.fire({
                text: "Product has been successfully Updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editProductModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }

    const brand = ref([]);
    const setBranddata = async (data) => {
        //loading.value = true;
        try {
            var values = {"search_term" : "a"}
            await store.dispatch(Actions.CUST_PRODUCT_BRAND, values).then(({ data }) => {
            brand.value = data;
            //loading.value = false;
            
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }
    }
   
    const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setProductData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });    

      // formRef.value.validate((valid) => {
      //   if (valid) {
      //     loading.value = true;

      //     setTimeout(() => {
      //       loading.value = false;

      //       Swal.fire({
      //         text: "Company has been successfully created.",
      //         icon: "success",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn btn-primary",
      //         },
      //       }).then(() => {
      //         hideModal(addCompanyModalRef.value);              
      //         router.go(0)
      //       });
      //     }, 2000);
      //   } else {
      //     Swal.fire({
      //       text: "Sorry, looks like there are some errors detected, please try again.",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });
      //     return false;
      //   }
      // });
    };

    onMounted( async () => {
      await setFormData(props.data);
      await setParentData("")
      await setBranddata("")
      await setProductDropdown("")
      await setCompanySellerdata(compseller.value)
      await setMakingdata(making.value)
      await setCompositiondata(comp.value)
      await setCompanyData(company.value)
      //await OnClickCompany(formData.value['company_select'])
       
    });
    return {      
      formData,      
      rules,
      submit,
      formRef,
      loading,
      editProductModalRef,
      //OnClickCompany,
      setBranddata,
      setCompanyData,
      setParentData,
      setMakingdata,
      setCompositiondata,
      setProductDropdown,
      setCompanySellerdata,
      compseller,
      productdata,
      brand,
      //branch,
      company,
      comp,
      making,
      parent,
      active_list_data,
    };
  },
});
