

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddProductGradeModal from "@/components/modals/forms/AddProductGradeModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, {business_types} from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import AddProductAttributeModal from "@/components/modals/forms/AddProductAttributeModal.vue";
import { Modal } from "bootstrap";


export default defineComponent({
 props: 
  {
      id: { required: true }
  },
  name: "price-listing",
  components: {
   AddProductAttributeModal,
  },
  
  setup(props) {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);    
    const loadingData = ref<boolean>(true);
    const searchTearm = ref('');
    
    var ids = ref(props.id);
    interface ViewPrice {
      attribute_name,  
      attribute_type,
    };

    var paginationData = ref({})
    var resultsView1 = ref<Array<ViewPrice>>([]);
    paginationData.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

    const product_attr_modal = (product_id) => {
      const modal = new Modal(document.getElementById("kt_product_modal_attribute"));
      modal.show();
      (document.querySelector('#product_attr_id') as HTMLInputElement).value = product_id;
    }
    
    var tableData = ref<Array<ViewPrice>>([]);
  
     const getProductPriceDetails = async () => {
     try {

      var values = { "product_id"  :  props.id}

      await store.dispatch(Actions.CUST_PRODUCT_ATTRIBUTE_LIST_DISPLAY, values).then(({ data }) => {

      tableData.value = ([]);
        for (var j = 0; j < data.attribute_list.length; j++){
          //var attribute_type_list: any[] = []
          var attribute_val_list: any[] = []
          for (var i = 0; i < data.attribute_list[j].attribute_data.length; i++)
          {
            //attribute_type_list.push(data.attribute_list[j].attribute_data[i].attribute_type)
            attribute_val_list.push(data.attribute_list[j].attribute_data[i].attribute_val)
          }
          resultsView1.value = Array({
            attribute_name: data.attribute_list[j].attribute_name,
            attribute_type: data.attribute_list[j].attribute_type,
            attribute_val: attribute_val_list,
          })
          tableData.value.splice(j, resultsView1.value.length, ...resultsView1.value); 
        }
          loadingData.value = false;

        }).catch(({ response }) => {
          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };
    
      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });
                    
      onMounted( async () => {
         await getProductPriceDetails();
      });  

      
      
    return {
      tableData,      
      checkedCompany,
      resultsView1,
      getProductPriceDetails,
      product_attr_modal,
      ids,
      count,      
      paginationData,
      loadingData
    };  

  }  

});
