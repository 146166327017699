
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Modal } from "bootstrap";
import { number } from "yup/lib/locale";
import Products from "@/views/apps/product/Products.vue";
export default defineComponent({
  name: "add-product-price-modal",
  components: {
    
    },
  data() {
    return {
      currentState: false,
      // inputs: [
      //       {
      //           piece: [],
      //           weight: [],
      //       }
      //   ],
        // loose: [
        //     {
        //         weight: '',
        //     }
        // ],
    }
  },
  methods: {
      
      

  },

  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const addInventoryModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const router = useRouter(); 
    const id1data =ref("");
    
    const formData = ref({
      price: "",      
      quantity: "",
      uom_select: "",
      value_select: "",
      branch_select: "",
      //inventory_select : "",
      fromdueDate: "",
      uptodueDate: "",
      incoterm_select: "",
      delivery_select: "",
      weight_select: "",
      piece_select: "",
      loading_applicable: "",
    });

    onMounted( async () => {
      await setUomdData()
      await setIncotermData()
      await setDeliveryData()
      await setCurrencyData()
      await setMaterialData()
      await setBranch(formData.value['product_id'])
    });
   
    const rules = ref({
      price: [
        {
          required: true,
          message: "Price is required",
          trigger: "change",  
          min : 0,   
        },  
      ],   
      uom_select: [
        {
          required: true,
          message: "UOM is required",
          trigger: "change",  
        },  
      ],   
      branch_select: [
        {
          required: true,
          message: "Branch is required",
          trigger: "change",  
        },  
      ],  
      value_select: [
        {
          required: true,
          message: "Currency is required",
          trigger: "change",  
        },  
      ], 
      quantity: [
        {
          required: true,
          message: "Quantity is required",
          trigger: "change",  
        },  
      ],  
      fromdueDate: [
        {
          required: true,
          message: "Date is required",
          trigger: "change",  
        },  
      ],  
      uptodueDate: [
        {
          required: true,
          message: "Date is required",
          trigger: "change",  
        },  
      ],    
      material_select :[
        {
          required: true,
          message: "Material condition is required",
          trigger: "change",  
        },  
      ], 
      no_pkg :[
        {
          required: true,
          message: "No. of package is required",
          trigger: "change",  
        },  
      ], 
      // piece_select: [
      //   {
      //     required: true,
      //     message: "Piece is required",
      //     trigger: "change",  
      //   },  
      // ], 
      // weight_select: [
      //   {
      //     required: true,
      //     message: "Weight is required",
      //     trigger: "change",  
      //   },  
      // ], 
    });

    const branch = ref([]);
    const setBranch = async (product_id) => {
      try {
            var values = { "product_id"  : (document.querySelector('#inventory_id') as HTMLInputElement).value }
            await store.dispatch(Actions.CUST_SELLER_BRANCH_LIST, values).then(({ data }) => {
            branch.value = data;
            
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }
    }

    const incoterm = ref([]);
    const setIncotermData = async () => {
        //loading.value = true;
        try {
          await store.dispatch(Actions.CUST_PRODUCT_INCOTERM).then(({ data }) => {
          incoterm.value = data;
          
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const delivery = ref([]);
    const setDeliveryData = async () => {
        //loading.value = true;
        try {
          await store.dispatch(Actions.CUST_PRODUCT_DELIVERY_TERM).then(({ data }) => {
          delivery.value = data;
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const uom = ref([]);
    const setUomdData = async () => {
        //loading.value = true;
        try {
          await store.dispatch(Actions.CUST_PRODUCT_UOM).then(({ data }) => {
          uom.value = data;
          console.log(uom.value)
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const currency = ref([]);
    const setCurrencyData = async () => {
        //loading.value = true;
        try {
          await store.dispatch(Actions.CUST_PRODUCT_CURRENCY).then(({ data }) => {
          currency.value = data;
          console.log(currency.value)
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const material = ref([]);
    const setMaterialData = async () => {
        //loading.value = true;
        try {
          await store.dispatch(Actions.CUST_PRODUCT_MATERIAL_CONDITION).then(({ data }) => {
          material.value = data;
          console.log(material.value)
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    function add(index) {
          index +=1
          inputs.value.push({ 
            sr_no: index+1,
            piece: "", 
            weight: "",
            
           });
           
      }

    function remove(index) {
          inputs.value.splice(index, 1);
      }

    const inputs = ref([
            {
                sr_no : 1,
                piece: "",
                weight: "",
            }
        ])

     const setPriceData = async (data) => {

      var product_id = ((document.querySelector("input[name=inventory_id]") as HTMLInputElement).value);
      console.log("product_id");

      let pkg_data = inputs.value.map((item) => {
        return {
          "sr_no": Number(item.sr_no),
          "piece_per_package" : Number(item.piece),
          "weight_per_package": Number(item.weight),
        }
        
      }); 
      
    //  let validatePackageList = inputs.value.filter((item) => {
    //     return (
    //       item.piece != "" &&
    //       item.weight != "" 
    //     );
    //   });
    //   if (
    //     validatePackageList === [] ||
    //     validatePackageList.length != inputs.value.length
    //   ) {
    //     loading.value = false;
    //     Swal.fire({
    //       text: "Please enter Piece/Weight.",
    //       icon: "error",
    //       buttonsStyling: false,
    //       confirmButtonText: "Ok, got it!",
    //       customClass: {
    //         confirmButton: "btn btn-primary",
    //       },
    //     });
        
    //     return false;
    //   }
      
      const db_data = {
        "product_id": Number(product_id),
        "branch_id" : data.branch_select,
        "product_price_per_uom": Number(data.price),
        "no_of_packages": Number(data.no_pkg),
        "primary_currency_id": data.value_select,  
        "material_condition_id": data.material_select,  
        "packages_json": pkg_data,
        "uom_id": data.uom_select,  
        "inward_qty": Number(data.quantity),
        "valid_from": data.fromdueDate,
        "valid_upto": data.uptodueDate,
        "incoterm_id": data.incoterm_select,
        "delivery_term_id": data.delivery_select,
        "is_loading_applicable": data.loading_applicable,
        "company_batch_no":"",
        "outward_qty":"0",
        "created_user_id" : 1
  
        }
        
        await store.dispatch(Actions.CUST_ADD_PRODUCT_INVENTORY, db_data).then(({ data }) => {
          if (data){
        
            setTimeout(() => {
              loading.value = false;
//
              Swal.fire({
                text: "Product Inventory added successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addInventoryModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }

    const submit = async () => {
      loading.value = true;
      if (!formRef.value) {
        loading.value = false;
        return;
      }
      formRef.value.validate (async (valid) => {

        if (valid) {

          await setPriceData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }
      });    
    };

    return {      
      formData,    
      inputs, 
      add, 
      remove,
      rules,
      submit,
      formRef,
      loading,
      addInventoryModalRef,
      setBranch,
      setUomdData,
      setMaterialData,
      setCurrencyData,
      setDeliveryData,
      setIncotermData,
      material,
      incoterm,
      delivery,
      branch,
      currency,
      uom,
    };
  },
});
