
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import EditProductModal from "@/components/modals/forms/EditProductModal.vue";
import ProductAttributeList from "@/views/apps/product/ProductAttributeList.vue";
import ProductInventoryList from "@/views/apps/product/ProductInventoryList.vue";
import ProductGradeMappingList from "@/views/apps/product/ProductGradeMappingList.vue";
export default defineComponent({
  props: 
  {
      id: { required: true }
  },
  name: "apps-product-details",
  components: {
   EditProductModal,
   ProductGradeMappingList,
   ProductAttributeList,
   ProductInventoryList,
  },
  methods: {
    
  },
  setup(props) {

    var ids = ref(props.id);
    const store = useStore();

   interface ViewProduct {
      product_id,
      product_name, 
      parent_product_name,  
      product_short_name,
      composition_name,
      category_name,
      making_process_name,
      company_name,
      seller_company,
      branch_name,
      brand_name,
      active : {
        label :string;
        color :string;
      }
    }; 
    
    interface ViewPrice {
      product_id,    
      inward_qty,   
      uom_name,
      product_price_per_uom,
      valid_from,
      valid_upto,
      currency_name,
      incoterm_display_text,
    };
    
    var resultsView = ref<ViewProduct>();
    var resultsView1 = ref<Array<ViewPrice>>([]);
    const loadingData = ref<boolean>(true);

    // resultsView1.value = {
    //   uom_name: "",
    //   currency_name:"",
    //   valid_from: "",
    //   valid_upto: "",
    //   product_id: "",
    //   product_price_per_uom: "",
    // }

    resultsView.value = {
      product_id: "",
      product_name :"",  
      parent_product_name : "", 
      product_short_name:"",  
      composition_name:"",  
      category_name:"",  
      making_process_name:"",  
      company_name:"",
      seller_company: "",
      branch_name:"",
      brand_name:"",
      active: {
        label: "",
        color: "",
      }
    }

    const getProductDetails = async () => {
     
      try {

      var values = { "product_id"  :  props.id, "page"  : 1, "records_per_page" : 1}

      await store.dispatch(Actions.CUST_GET_PRODUCT_LIST, values).then(({ data }) => {
        var status_label = ""
        var status_color = ""
        var active_label = ""
        var active_color = ""

        if (data[0].active){
            active_label = "Yes";
            active_color = "success";                  
        }else{
            active_label = "No";
            active_color = "danger";
        }
     
        resultsView.value = {
          product_id: data[0].product_id,
          product_name :  data[0].product_name,
          parent_product_name : data[0].parent_product_name,
          product_short_name : data[0].product_short_name,
          composition_name: data[0].composition_name,
          category_name: data[0].category_name,
          making_process_name: data[0].making_process_name,
          company_name: data[0].company_name,
          seller_company: data[0].parent_company_name,
          branch_name: data[0].branch_name,
          brand_name: data[0].brand_name,
          active: {
            label: active_label,
            color: active_color
          },
        }
      })
      .catch(({ response }) => {

        console.log(response);

      });
        
      } catch (e) {
        console.log(e);
      }
    }; 

    var tableData = ref<Array<ViewPrice>>([]);

    const getProductPriceDetails = async () => {
     try {

      var values = { "product_id"  :  props.id}

      await store.dispatch(Actions.CUST_GET_PRODUCT_PRICE_LIST, values).then(({ data }) => {

      tableData.value = ([]);

          for (var j = 0; j < data.length; j++){
              resultsView1.value = Array({
                inward_qty: data[j].inward_qty,
                  uom_name: data[j].uom_name,
                  currency_name:data[j].currency_name,
                  valid_from: data[j].valid_from,
                  valid_upto: data[j].valid_upto,
                  product_id: data[j].product_id,
                  product_price_per_uom: data[j].product_price_per_uom,
                  incoterm_display_text: data[j].incoterm_display_text,
                  delivery_term_display_text: data[j].delivery_term_display_text
              })
              tableData.value.splice(j, resultsView1.value.length, ...resultsView1.value); 
          }
            
           loadingData.value = false;

        }).catch(({ response }) => {
              
          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };
    
    onMounted( async () => {
      await getProductDetails();
      await getProductPriceDetails();
      setCurrentPageBreadcrumbs("Product Details", ["Product"]);
    });


    return {
      resultsView,
      resultsView1,
      tableData,
      loadingData,
      getProductPriceDetails,
      ids,
    };

  }
});
